export enum WidgetDataHooks {
  MyBookingsMainWidget = 'my-bookings-widget',
}

export enum HeaderDataHooks {
  Main = 'header-main',
  PageTitle = 'page-title',
  PageSubtitle = 'page-subtitle',
}

export enum TimezoneDropdownDataHooks {
  Main = 'timezone-dropdown-main',
  Dropdown = 'timezone-dropdown',
  TimezoneText = 'timezone-text',
}

export enum BookingsLayoutDataHooks {
  Main = 'bookings-layout-main',
  TabbedContent = 'bookings-layout-tabbed-content',
  Spinner = 'bookings-layout-spinner',
}

export enum EmptyStateDataHooks {
  Main = 'empty-state-main',
  Text = 'empty-state-title',
  Button = 'empty-state-button',
}

export enum BookingsListDataHooks {
  Main = 'bookings-list-main',
  BookingsDetailsWrapper = 'bookings-list-bookings-details-wrapper',
  Accordion = 'bookings-kist-accordion',
  BookingDetails = 'bookings-list-booking-details',
}

export enum BookingDetailsDataHooks {
  Main = 'booking-details-main',
  PendingApprovalBadge = 'booking-details-pending-approval-badge',
  WaitlistBadge = 'booking-details-waitlist-badge',
  ManageButton = 'booking-details-manage-button',
}

export enum ManageButtonDataHooks {
  Main = 'my-bookings-manage-button-main',
  PopoverWrapper = 'my-bookings-manage-button-popover-wrapper',
  MobileDrawer = 'my-bookings-manage-button-mobile-drawer',
  Button = 'my-bookings-manage-button',
  MobileHeaderContainer = 'my-bookings-manage-button-mobile-header-container',
  MobileHeaderCloseButton = 'my-bookings-manage-button-mobile-header-close-button',
  MobileHeaderTitle = 'my-bookings-manage-button-mobile-header-title',
  MobileHeaderSubtitle = 'my-bookings-manage-button-mobile-header-subtitle',
}

export enum ActionsMenuDataHooks {
  ActionsMenuContainer = 'my-bookings-manage-action-menu-layout-main',
  ManageActionMenuDivider = 'my-bookings-manage-action-menu-divider',
}

export enum ActionsButtonsDataHooks {
  BookAgainButton = 'my-bookings-actions-buttons-book-again-button',
  BookAgainMenu = 'my-bookings-actions-buttons-book-again-menu',
  RescheduleButton = 'my-bookings-actions-buttons-reschedule-button',
  RescheduleMenu = 'my-bookings-actions-buttons-reschedule-menu',
  Reschedule = 'my-bookings-actions-buttons-reschedule',
  CancelMenu = 'my-bookings-actions-buttons-cancel-menu',
}

import React, { FC } from 'react';
import { useApplicationContext } from '../../../../../core/hooks/useApplicationContext';
import { TabTypes } from '../../../../../types';
import { BookingsListDataHooks } from '../../datahooks';
import { Accordion } from 'wix-ui-tpa/cssVars';
import { classes, st } from './BookingsList.st.css';
import { renderBookingDetails } from './BookingDetails';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useGroupHeaderGetters } from '../../../../../core/hooks/useGroupHeaderGetter';

export const BookingsList: FC = () => {
  const { upcomingData, historyData, selectedTab } = useApplicationContext();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { getTitle, getSubtitle } = useGroupHeaderGetters();

  const data =
    selectedTab.type === TabTypes.UPCOMING ? upcomingData : historyData;

  if (!data) {
    return null;
  }
  return (
    <div className={classes.root} data-hook={BookingsListDataHooks.Main}>
      <Accordion
        className={st(classes.accordion, { isMobile })}
        data-hook={BookingsListDataHooks.Accordion}
      >
        {data.groups.map((group) =>
          renderBookingDetails({
            group,
            t,
            isMobile,
            selectedTab,
            title: getTitle(group),
            subtitle: getSubtitle(group),
          }),
        )}
      </Accordion>
    </div>
  );
};

import React, { useEffect } from 'react';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { Close } from '@wix/wix-ui-icons-common/on-stage';
import { MobileDrawer, Popover, Button, IconButton } from 'wix-ui-tpa/cssVars';
import { classes, st } from './ManageButton.st.css';
import { ActionMenuConfiguration, BookingsGroup } from '../../../../types';
import { handleWithStopPropagation } from '../../../../utils';
import { ActionsMenuDataHooks, ManageButtonDataHooks } from '../datahooks';
import Text, { TextType } from '../../Components/Text';
import { useGroupHeaderGetters } from '../../../../core/hooks/useGroupHeaderGetter';
import { getMenuActionsConfiguration } from './MenuConfiguration';

export type ManageButtonProps = {
  group: BookingsGroup;
};

export const ManageButton: React.FC<ManageButtonProps> = ({ group }) => {
  const { isMobile, isRTL } = useEnvironment();
  const { t } = useTranslation();
  const [shown, setShown] = React.useState(false);
  const [actionsConfig, setActionsConfig] = React.useState<
    ActionMenuConfiguration[] | null
  >(null);

  useEffect(() => {
    setActionsConfig(getMenuActionsConfiguration(group, () => setShown(false)));
  }, [group.allowedActions, group.bookings?.[0]?.bookingId]);

  if (!actionsConfig) {
    return null;
  }

  const renderButton = (onClick: React.MouseEventHandler) => {
    return (
      <Button
        upgrade
        data-hook={ManageButtonDataHooks.Button}
        className={classes.manageButton}
        onClick={(e) => handleWithStopPropagation(e, onClick)}
      >
        {t('app.my-bookings-widget.manage-actions.button.label')}
      </Button>
    );
  };

  const renderMenuActions = () => {
    return (
      <div
        onClick={handleWithStopPropagation}
        className={classes.actionsMenuContainer}
        data-hook={ActionsMenuDataHooks.ActionsMenuContainer}
      >
        {actionsConfig.map((actionConfig) => actionConfig.Component)}
      </div>
    );
  };

  const drawerAndPopoverProps: DrawerAndPopoverProps = {
    group,
    renderButton,
    shown,
    setShown,
    renderMenuActions,
  };

  return (
    <div
      className={st(classes.root, { isMobile, isRTL })}
      data-hook={ManageButtonDataHooks.Main}
    >
      {isMobile ? (
        <DrawerManageButton {...drawerAndPopoverProps} />
      ) : (
        <PopoverManageButton {...drawerAndPopoverProps} />
      )}
    </div>
  );
};

export interface DrawerAndPopoverProps {
  group: BookingsGroup;
  renderMenuActions: () => React.ReactNode;
  renderButton: (onClick: React.MouseEventHandler) => React.ReactNode;
  shown: boolean;
  setShown: (shown: boolean) => void;
}

export const PopoverManageButton: React.FC<DrawerAndPopoverProps> = ({
  group,
  renderButton,
  shown,
  setShown,
  renderMenuActions,
}) => {
  return (
    <div className={classes.root} onClick={handleWithStopPropagation}>
      <Popover
        placement="left-start"
        showArrow={false}
        data-hook={ManageButtonDataHooks.PopoverWrapper}
        shown={shown}
        className={classes.popover}
        onClickOutside={(e: React.MouseEvent) =>
          handleWithStopPropagation(e, () => setShown(false))
        }
      >
        <Popover.Element>
          {renderButton(() => setShown(!shown))}
        </Popover.Element>
        <Popover.Content>{renderMenuActions()}</Popover.Content>
      </Popover>
    </div>
  );
};

export const DrawerManageButton: React.FC<DrawerAndPopoverProps> = ({
  group,
  renderButton,
  shown,
  setShown,
  renderMenuActions,
}) => {
  const { getTitle, getSubtitle } = useGroupHeaderGetters();
  const { isMobile } = useEnvironment();

  const renderMobileMenuHeader = () => {
    if (!isMobile) {
      return null;
    }

    return (
      <div
        data-hook={ManageButtonDataHooks.MobileHeaderContainer}
        className={classes.groupMobileHeader}
      >
        <IconButton
          data-hook={ManageButtonDataHooks.MobileHeaderCloseButton}
          icon={<Close />}
          className={classes.closeButton}
          onClick={() => setShown(false)}
        />
        <Text
          data-hook={ManageButtonDataHooks.MobileHeaderTitle}
          type={TextType.Primary}
          tagName="p"
        >
          {getTitle(group)}
        </Text>
        <Text
          data-hook={ManageButtonDataHooks.MobileHeaderSubtitle}
          type={TextType.Secondary}
          tagName="p"
        >
          {getSubtitle(group)}
        </Text>
      </div>
    );
  };

  return (
    <div onClick={handleWithStopPropagation}>
      {renderButton(() => setShown(true))}
      <MobileDrawer
        isOpen={shown}
        onRequestClose={() => setShown(false)}
        data-hook={ManageButtonDataHooks.MobileDrawer}
      >
        {renderMobileMenuHeader()}
        {renderMenuActions()}
      </MobileDrawer>
    </div>
  );
};

import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Delete } from '@wix/wix-ui-icons-common/on-stage';
import { ActionsMenuLayout } from 'wix-ui-tpa/cssVars';
import { classes as classesActionsMenu } from '../../ManageButton.st.css';
import {
  ActionButtonUiTypes,
  ActionsMenuType,
  BookingsGroup,
} from '../../../../../../types';
import { ActionsButtonsDataHooks } from '../../../datahooks';

export interface CancelProps {
  type: ActionButtonUiTypes;
  group: BookingsGroup;
  onActionClick?: (type: ActionsMenuType) => void;
}

export const Cancel: React.FC<CancelProps> = ({
  type,
  group,
  onActionClick,
}) => {
  const { t } = useTranslation();

  if (type === ActionButtonUiTypes.MENU) {
    return (
      <div data-hook={ActionsButtonsDataHooks.CancelMenu}>
        <ActionsMenuLayout className={classesActionsMenu.actionMenuLayout}>
          <ActionsMenuLayout.Item
            onClick={() => {
              onActionClick?.(ActionsMenuType.CANCEL);
            }}
            prefixIcon={<Delete />}
            content={t('app.my-bookings-widget.book-cancel.label')}
          />
        </ActionsMenuLayout>
      </div>
    );
  }

  return null;
};

import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Calendar } from '@wix/wix-ui-icons-common/on-stage';
import { ActionsMenuLayout, Button } from 'wix-ui-tpa/cssVars';
import { classes as classesActionsMenu } from '../../ManageButton.st.css';
import {
  ActionButtonUiTypes,
  ActionsMenuType,
  BookingsGroup,
} from '../../../../../../types';
import { ActionsButtonsDataHooks } from '../../../datahooks';

export interface BookAgainProps {
  group: BookingsGroup;
  type: ActionButtonUiTypes;
  onActionClick?: (type: ActionsMenuType) => void;
}

export const BookAgain: React.FC<BookAgainProps> = ({
  group,
  onActionClick,
  type,
}) => {
  const { t } = useTranslation();

  if (type === ActionButtonUiTypes.MENU) {
    return (
      <div data-hook={ActionsButtonsDataHooks.BookAgainMenu}>
        <ActionsMenuLayout className={classesActionsMenu.actionMenuLayout}>
          <ActionsMenuLayout.Item
            onClick={() => {
              onActionClick?.(ActionsMenuType.BOOK_AGAIN);
            }}
            prefixIcon={<Calendar />}
            content={t('app.my-bookings-widget.book-another.button.label')}
          />
        </ActionsMenuLayout>
      </div>
    );
  }

  return (
    <div data-hook={ActionsButtonsDataHooks.BookAgainButton}>
      <Button>{t('app.my-bookings-widget.book-another.button.label')}</Button>
    </div>
  );
};

import { useFormatters } from '../useFormatters';
import { BookingsGroup } from '../../../types';
import { isCourse } from '../../../utils/bookInfo';
import { useCallback } from 'react';
import { useApplicationContext } from '../useApplicationContext';

export interface TitleAndSubtitleGetters {
  getTitle: (group: BookingsGroup) => string;
  getSubtitle: (group: BookingsGroup) => string;
}

export const useGroupHeaderGetters = () => {
  const { getStartDate, getStartTime, getDuration } = useFormatters();
  const { selectedTimezone } = useApplicationContext();

  const getTitle = useCallback(
    (group: BookingsGroup) =>
      group.bookings.map((booking) => booking.offering?.name).join(', '),
    [],
  );

  const getSubtitle = useCallback(
    (group: BookingsGroup) => {
      const { bookings } = group;
      const firstBooking = bookings[0];
      const lastBooking = bookings[bookings.length - 1];
      const startDate = new Date(firstBooking.start);
      const endDate = new Date(lastBooking.end);

      const formattedDateAndTimeList = [getStartDate(startDate)];

      if (!isCourse(group)) {
        formattedDateAndTimeList.push(
          getStartTime(startDate),
          getDuration(startDate, endDate),
        );
      }

      return formattedDateAndTimeList
        .filter((formatted) => !!formatted)
        .join(', ');
    },
    [selectedTimezone?.timezone],
  );

  return { getTitle, getSubtitle };
};
